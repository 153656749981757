<template>
  <div>
  <div class="row" v-if="validClient">
    <div class="col-md-8">
      <Tabs :tabs=clientTabs :state=state :info-tab-title=login :uiList=$store.state.userSettings.uiClientTabs />
    </div>
    <div class="col-md-4">
      <Panel title="Новости" icon=home :toolbar=newsToolbar :low-toolbar=true @toolbar-click=newsClick >
        <RadixModule ref=news path="/client/news/news" :state=state />
      </Panel>
      <Panel title="Заметки" icon=comment :toolbar=noteToolbar :low-toolbar=true @toolbar-click=noteClick >
        <RadixModule ref=notes path="/client/note/note" :state=state />
      </Panel>
      <Panel title="Деньги" icon=usd :toolbar=moneyToolbar :low-toolbar=true :padding=true @toolbar-click=moneyClick >
        <RadixModule ref=money path="/client/money/money" :state=state />
      </Panel>
      <Panel title="Заявки" icon=tasks :toolbar=taskToolbar :low-toolbar=true @toolbar-click=taskClick >
        <RadixModule ref=tasks path="/client/task/list" :state=state />
      </Panel>
    </div>
  </div>
  <h4 v-else>Абонент {{state.uid}} не найден</h4>
  </div>
</template>

<script>
import RadixModule from "RadixModule.vue"
import {eventBus} from "main.js"
import Tabs from "common/visual/Tabs.vue"
import Backbone from "backbone-ex"
import radio from "radio"
import * as darsan from "darsan"
import {setTitle} from "navigation"
import * as clientTabs from "client/page/clientTabs"
import {domainByUid} from "sysconf.js"
import * as dialog from "common/dialog-bs/dialog"

let model

export default {
  name: "ClientPanel",
  components: {RadixModule, Tabs},
  
  props: {
    modus: Object,
  },
  
  data()
  {
    return {
    
      login: null,
      
      validClient: false,
      
      state: {
        _rest: "",
        _tail: "",
        subpath: "",
        uid: "",
      },
      
      newsToolbar: [
        {
          name: "all",
          toggle: ["Все","Активные"],
          opened: false,
        },
      ],
      
      noteToolbar: [
        {
          name: "add",
          icon: "plus",
          text: "Добавить",
        },
      ],
      
    }
  },
  
  created()
  {
    const infoModel = Backbone.ModelEx.extend({
      idAttribute: "entity"
    })
                        
    model = new infoModel();
    radio.channel('owners').reply('model:client:info', () => model)

    this.setState()
  },
  
  methods: {
    
    newsClick(msg)
    {
      if (msg.from=="all")
      {
        this.$refs.news.$emit("call", {method: "toggleHide", args: [msg.opened]})
      }
    },
    
    noteClick(msg)
    {
      if (msg.from=="add")
      {
        this.$refs.notes.$emit("call", {method: "toggleAdd"})
      }
    },
    
    moneyClick(msg)
    {
      switch(msg.from)
      {
        case "temp-credit":
           this.$refs.money.$emit("call", {method: "temporaryCredit"})
        break
        case "accounts":
          this.$refs.money.$emit("call", {method: "toggleAccount"})
        break
        case "payments":
          this.$refs.money.$emit("call", {method: "showPayments"})
        break;  
        case "reductions":
          this.$refs.money.$emit("call", {method: "showReductions"})
        break;  
      }
    },

    async taskClick(msg)
    {
      switch(msg.from)
      {
        case "show":
           this.$refs.tasks.$emit("call", {method: "toggleList"})
        break
        case "oper":
//          var d = await dialog.showComponent("issue/page/issue-page", "Мастер оформления заявки", { uid: this.state.uid, dialog: dialog })
//          d.once('close', () => this.$refs.tasks.$emit("call", {method: "toggleList"}))
            await dialog.showComponent("helper/page/helper-page", "Помощник оператора", { uid: this.state.uid, dialog: dialog, master: "/master/1" })
        break
        case "add":
          this.$refs.tasks.$emit("call", {method: "newTask"})
        break;  
      }
    },
    
    setState()
    {
      const path = this.modus.path
      const params = this.modus.params

      const s = {}

      const parts = path.split("/")
      s._rest = "/" + parts.slice(3).join("/")

      s.uid = parts[2]
      s.subpath = parts[3]
      s._tail = parts[4]
      
      s.params = params
      for (const p of new URLSearchParams(params))
      {
        s[ p[0] ] =  p[1]
      }
      
      const oldUid =  this.state.uid
      
      if (parts[2] != oldUid)
      { 
        const prefix = domainByUid(s.uid)
                       
        model.url = darsan.makeURL(prefix, "client", "/client/" + s.uid)
        model.clear({silent: true})

        model.fetchm().done( () => 
        {
          this.state = s

          const toShow = this.$store.state.config.local_flavor.show_client_login ? model.get("login") : this.state.uid
          this.login = toShow      
          setTitle(toShow)

          this.validClient = true
        })
        .catch((model, xhr) => 
        {
          this.state = s
          if (xhr.status==404) this.validClient = false
        })
      }
      else
      {
        this.state = s
      }
    },        
    
  },
  
  computed: {
    moneyToolbar()
    {
      const buttons = []
    
      if (this.$store.getters.permGet('billing.client.credit.temporary.set')) 
      {
        buttons.push({ name: "temp-credit", text: "", icon: "time"})
      }
                      
      if ( this.$store.getters.permGet( "billing.money.list" ) ) 
      {
        buttons.push(
          { name: "payments", text: "Оплаты", icon: "plus" },
          { name: "reductions", text: "Снятия", icon: "minus" }, 
          { name: "accounts", icon: "shopping-cart", opened: false, toggle: ["Доп. счета","Доп. счета"]  },
        )
      }
      
      return {name: "money-toolbar", buttons, low: true}
    },

    taskToolbar()
    {
      const toolbar = [
       { name: "show", opened: false, toggle: ["Все","Активные"] },
      ]
      
      if ( this.$store.getters.permCanWrite("task.data") ) 
      {
        toolbar.unshift(
          { name: "oper", text: 'Помощник', icon: "fa-bolt" },
          { name: "add", text: 'Добавить', icon: "plus" },
        )
      }

      return toolbar
    },
    
    clientTabs()
    {
      return clientTabs.default
    },

  },
  
  watch: {
    modus(val) { this.setState() },
  },

}

</script>

